<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <h1 class="text-center">Noticias</h1>
          <v-simple-table class="mt-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Nombre
                  </th>
                  <th class="text-left">
                    Descripción
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listItemsPost.datos" :key="item.name">
                  <td>{{ item.noticiaTitle }}</td>
                  <td>{{ item.noticiaAbstracts }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Post",
  data: () => {
    return {
      listItemsPost: []
    };
  },
  mounted() {
    this._getListPost();
  },
  methods: {
    _getListPost() {
      var vm = this;
      var data = JSON.stringify({
        url: "pic-importadores.com"
      });

      var config = {
        method: "post",
        url: "https://api-admin.pic-importadores.com/api/listNewsForPage",
        headers: {
          "Content-Type": "application/json"
        },
        data: data
      };

      axios(config)
        .then(function(response) {
          vm.listItemsPost = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
